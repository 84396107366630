.modal-content {
	.booking-detail-info {
		display: flex;
		margin-bottom: 8px;
		p {
			margin: 0;
			min-width: 120px;
		}
	}
}
.entity-features-wrapper {
	display: flex;
	flex-wrap: wrap;
	.feature-label {
		background-color: $gray-DF;
		color: $gray-33;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 5px 5px 0;
		border-radius: 12px;
		padding: 5px;

		p {
			width: auto;
			text-align: center;
			margin: 0;
		}
	}
}
