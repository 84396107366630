@import './base/colors.scss';
@import './base/breakpoints.scss';
@import './base/typography.scss';

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	isolation: isolate;
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
	min-height: 100%;
	background: $gray-F5;
}

.content-wrap {
	padding-bottom: 80px;
}

.loading-page {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
