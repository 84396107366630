@import './base/colors.scss';
@import './base/breakpoints.scss';
@import './base/typography.scss';

$column-gap: 24px;

.grid {
	width: 100%;
	max-width: $breakpoint-mq5-max;
	box-sizing: border-box;
	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	column-gap: $column-gap;
	margin: 0 auto;
	padding: 0px 40px;
}

@media (max-width: $breakpoint-mq5) {
	.grid {
		column-gap: $column-gap;
		padding: 0px 40px;
	}
}

@media (max-width: $breakpoint-mq4) {
	.grid {
		column-gap: $column-gap;
		padding: 0px 32px;
	}
}

@media (max-width: $breakpoint-mq3) {
	.grid {
		column-gap: $column-gap;
		padding: 0px 32px;
	}
}

@media (max-width: $breakpoint-mq2) {
	$column-gap: 16px;

	.grid {
		column-gap: $column-gap;
		padding: 0px 24px;
	}
}

@media (max-width: $breakpoint-mq1) {
	$column-gap: 16px;

	.grid {
		column-gap: $column-gap;
		padding: 0px 16px;
	}
}
