.initial-office-selection-page {
	display: flex;
	width: 100vw;
	height: 100vh;

	&-content {
		background-color: $white;
		margin: 0 40px;
		min-width: 40%;
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: center;
		gap: 20%;

		@media only screen and (max-width: $breakpoint-mq3) {
			width: 100%;
		}

		&-details {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: flex-start;

			.doorjames-logo {
				margin-bottom: 40px;
			}

			.header {
				margin: 8px 0 0 0;
				width: 100%;
			}

			img {
				width: 110px;
			}
		}
	}
	&-image-wrapper {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		background: $purple-40;

		img {
			width: 100%;
			height: 100%;
		}
		@media only screen and (max-width: $breakpoint-mq3) {
			display: none;
		}
	}
}
