.modal-content {
	.teak-entity-list-view {
		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 24px;
		}

		&-wrapper {
			max-height: 500px;
			overflow-y: scroll;
		}
		&-content {
			list-style: none;
			display: grid;
			grid-template-columns: repeat(4, minmax(0, 25%));
			gap: 16px;
			padding: 0;
			padding-bottom: 50px;

			@media (max-width: $breakpoint-mq5) {
				grid-template-columns: repeat(3, minmax(0, 32%));
			}

			@media (max-width: $breakpoint-mq4) {
				grid-template-columns: repeat(2, minmax(0, 50%));
			}

			@media (max-width: $breakpoint-mq3) {
				grid-template-columns: 100%;
			}
		}
	}

	.area-section {
		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}
}
