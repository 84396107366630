.modal-content {
	#new-booking-form {
		height: 100%;
		max-width: 700px;
		border: none;
		display: flex;
		flex-flow: column;
		gap: 20px;
		flex-grow: 2;

		.asset-type-radio {
			margin-top: 24px;
		}

		h2 {
			margin-bottom: 16px;
		}
	}
}
