$purple-180: #100b22;
$purple-160: #211644;
$purple-140: #312067;
$purple-120: #422b89;
$purple-100: #5236ab;
$purple-80: #755ebc;
$purple-60: #9786cd;
$purple-40: #baafdd;
$purple-20: #dcd7ee;
$purple-10: #eeebf7;

$magenta: #a82262;

$black: #000000;

$gray-33: #333333;
$gray-46: #464646;
$gray-70: #707070;
$gray-90: #909090;
$gray-DF: #dfdfdf;
$gray-F5: #f5f5f5;

$white: #ffffff;

$green: #2ac769;
$green-light: #d8f6e5;

$yellow-dark: #ffac25;
$yellow: #ffdd85;

$red-120: #85031a;
$red-110: #b10422;
$red-100: #dd052b;
$red-light: #fbdde1;

$blue: #1c72e3;
$blue-light: #c8dcfc;

$color-main: $purple-100;
$color-secondary: $magenta;
