.otp-inputs-wrapper {
	display: flex;
	flex-flow: column;
	margin: 40px 0;
}
.otp-input {
	display: flex;
	align-items: center;

	.otp-input-label {
		display: none;
	}
	.single-otp-input-wrapper {
		position: relative;

		.border-bottom-input {
			width: 80%;
			height: 1px;
			color: $purple-100;
			width: 50%;
			height: 2px;
			background-color: #5236ab;
			position: absolute;
			bottom: 10px;
			left: 16px;
			@media only screen and (max-width: $breakpoint-mq3) {
				width: 30%;
				bottom: 5px;
			}
		}
		.single-otp-input {
			max-width: 72px;
			height: 72px;
			border-radius: 18px;
			margin-right: 16px;
			border: 1px solid $gray-90;
			font-size: 2rem;
			@media only screen and (max-width: $breakpoint-mq3) {
				max-width: 50px;
				height: 50px;
			}

			@media only screen and (max-width: $breakpoint-mq2) {
				max-width: 30px;
				height: 30px;
				border-radius: 10px;
				margin: 0 4px;
				font-size: 1.5rem;
			}
		}
	}
	.otp-separator {
		width: 16px;
		height: 1.5px;
		background: $gray-DF;
		margin-right: 16px;
		@media only screen and (max-width: $breakpoint-mq2) {
			margin: 0 4px;
		}
	}
}

.otp-input-error {
	color: $red-100;
	text-align: left;
}

.otp-input-warning {
	color: $yellow-dark;
	text-align: left;
}
