.otp-page {
	background-color: $gray-F5;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	flex-flow: column;

	&-back {
		display: flex;
		width: 100%;
		justify-content: flex-start;
		.back-button {
			margin: 32px;
		}
	}
	&-modal {
		max-width: 650px;
		max-height: 530px;
		background-color: $white;
		border-radius: 32px;
		padding: 70px 60px;
		margin-top: 50px;
		display: flex;
		flex-flow: column;
		align-items: center;
		@media only screen and (max-width: $breakpoint-mq3) {
			padding: 35px 30px;
			margin-top: 20px;
		}

		@media only screen and (max-width: $breakpoint-mq2) {
			padding: 0;
			margin-top: 0;
		}

		.resend-timer {
			display: flex;
			gap: 5px;
			align-items: center;
		}

		.verify-otp-button {
			width: 100%;
			background-color: $purple-20;
			color: $purple-100;
			border-radius: 8px;
		}
	}
}
