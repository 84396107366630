@import './base/breakpoints.scss';
@import './base/colors.scss';

.footer {
	position: absolute;
	width: 100%;
	height: 63px;
	bottom: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 40px;
	background: $gray-F5;
	border-top: 1px solid $gray-DF;

	@media (max-width: $breakpoint-mq2) {
		padding: 8px 4px;
	}

	&-copyright {
		width: 50%;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		padding: 0px 1px;
		gap: 0 17px;
		flex: none;
		order: 0;
		flex-grow: 1;
	}

	&-links {
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 50%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;
		gap: 0 20px;
		flex: none;
		order: 1;
		flex-grow: 0;

		@media (max-width: $breakpoint-mq1) {
			&-item {
				font-size: 10px;
			}
		}
	}
}
