.modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	background: rgba(red($gray-70), green($gray-70), blue($gray-70), 0.7);
	z-index: 100;
	@media (max-height: 800px) {
		overflow-y: scroll;
	}

	.grid {
		margin-top: 50px;
		max-height: 80%;
		grid-template-rows: 1fr;
	}
	&-wrapper {
		background-color: $white;
		grid-column: 1 / -1;
		border-radius: 20px;
		display: flex;
		flex-flow: column;
		padding: 18px 24px;

		.date-time-wrapper-row {
			display: flex;
			justify-content: space-between;

			.date-wrapper {
				width: 50%;
			}
			.time-wrapper {
				width: 48%;
			}
		}
	}
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $gray-DF;
	}

	&-content {
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		flex-grow: 2;
	}

	&-footer {
		display: flex;
		justify-content: flex-end;
		border-top: 1px solid $gray-DF;
		padding-top: 16px;
	}
	.space-between-elements {
		justify-content: space-between;
	}
	.center-elements {
		justify-content: center;
		gap: 12px;
	}
}

@import './bookingModal/newBookingForm.scss';
@import './bookingModal/teakEntityList.scss';
@import './bookingModal/bookingSummaryModal.scss';
@import './cancelBookingModal/cancelBookingModal.scss';
