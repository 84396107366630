.booking-card-status {
	&-wrapper {
		display: flex;
		justify-content: flex-end;
		margin: 0 16px;
	}

	display: flex;
	justify-content: center;
	padding: 5px 10px;
	font-weight: bold;
	width: fit-content;
	border-radius: 5px;

	p {
		margin: 0;
	}
}

.status-cancelled {
	background-color: rgba(red($red-100), green($red-100), blue($red-100), 0.4);
	color: $red-100;
}

.status-expired,
.status-checkInForgotten {
	background-color: rgba(red($purple-100), green($purple-100), blue($purple-100), 0.4);
	color: $purple-100;
}

.status-checkOutForgotten {
	background-color: rgba(red($gray-46), green($gray-46), blue($gray-46), 0.4);
	color: $gray-46;
}

.status-checkedOut {
	background-color: rgba(red($blue), green($blue), blue($blue), 0.4);
	color: $blue;
}

.status-checkedIn,
.status-autoCheckedIn,
.status-checkInOpen,
.status-confirmed {
	background-color: rgba(red($green), green($green), blue($green), 0.4);
	color: $green;
}
