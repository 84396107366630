//We make use of rem for our font sizes.
// 10px = 0.625rem
// 12px = 0.75rem
// 14px = 0.875rem
// 16px = 1rem (base)
// 18px = 1.125rem
// 20px = 1.25rem
// 24px = 1.5rem
// 30px = 1.875rem
// 32px = 2rem

$font-primary: 'Roboto';

//Body elements
@mixin body($font-size, $font-weight, $line-height, $color) {
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
	color: $color;
}

//Body 1 - 16px, Regular/400/Line height 21
.body-normal {
	@include body(1rem, 400, 21, $gray-46);
}

//Body 1 selected - 16px, Bold/700/Line height 21
.body-normal-bold {
	@include body(1rem, 700, 21, $gray-46);
}

//Body 2 - 14px, Regular/400/Line height 18
.body-small {
	@include body(0.9rem, 400, 18, $gray-46);
}

//Title elements
@mixin title($font-size, $font-weight, $line-height, $color) {
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
	color: $color;
}

//Title 1 - 32px, Medium/500/Line height 42
.title-1 {
	@include title(2rem, 500, 42, $gray-46);
}

//Title 2 - 24px, Bold/700/Line height 32
.title-2 {
	@include title(1.5rem, 700, 32, $gray-46);
}

//Title 3 - 20px, Bold/700/Line height 26
.title-3 {
	@include title(1.3rem, 700, 26, $gray-46);
}

//Title 4 - 18px, Bold/700/Line height 24
.title-4 {
	@include title(1.1rem, 700, 24, $gray-46);
}

//Subtitle elements
@mixin subtitle($font-size, $font-weight, $line-height, $color) {
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
	color: $color;
}

//Subtitle 1 - 20px, Regular/400/Line height 26
.subtitle-1 {
	@include title(1.3, 400, 26, $gray-46);
}

//Subtitle 2 - 16px, Medium/500/Line height 21
.subtitle-2 {
	@include title(1rem, 500, 21, $gray-46);
}

//Subtitle 3 - 14px, Medium/500/Line height 18
.subtitle-3 {
	@include title(0.9rem, 500, 18, $gray-46);
}
