@import './base/breakpoints.scss';
@import './base/colors.scss';

.main-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	width: 100%;
	height: 64px;
	flex: none;
	order: 0;
	flex-grow: 0;
	z-index: 0;
	background: $white;
	box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);

	&-nav {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 0px;
		gap: 16px;
		position: relative;
		width: auto;
		height: 32px;
		margin-right: 44px;

		@media (max-width: $breakpoint-mq2) {
			gap: 8px;
			margin-right: 11px;
		}

		@media (max-width: $breakpoint-mq1) {
			gap: 4px;
			margin-right: 0;
		}

		&-dropdown {
			flex: none;
			order: 0;
			flex-grow: 0;
		}

		&-part {
			width: 0px;
			height: 32px;
			border: 1px solid $gray-F5;
			flex: none;
			order: 1;
			flex-grow: 0;
		}

		&-persona {
			flex: none;
			order: 2;
			flex-grow: 0;
		}
	}
}

.logo-doorjames {
	position: relative;
	width: 81px;
	height: 37px;
	margin-left: 41px;

	@media (max-width: $breakpoint-mq2) {
		width: 40px;
		height: 18px;
		margin-left: 8px;
	}

	@media (max-width: $breakpoint-mq1) {
		width: 0;
		height: 0;
		margin-left: 0;
	}
}
