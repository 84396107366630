.profile-completion-page {
	&-content {
		form {
			width: 100%;
			display: flex;
			flex-flow: column;
			gap: 16px;
		}
	}
}
