@import './base/colors.scss';
@import './base/breakpoints.scss';
@import './base/typography.scss';

html {
	font-family: $font-primary;
}

html,
body,
#root {
	height: 100%;
}

@import './app.scss';
@import './grid.scss';
@import './header.scss';
@import './footer.scss';

@import './components/bookingCard.scss';
@import './components/dateTimePicker/dateTimePicker.scss';

@import './pages/dashboard.scss';
@import './pages/login/login.scss';
@import './pages/otp/otp.scss';
@import './pages/otp/otpInput.scss';
@import './pages/modal/modal.scss';
@import './pages/initialOfficeSelection/initialOfficeSelection.scss';
@import './pages/profileCompletion/profileCompletion.scss';
