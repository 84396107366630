@import '../grid.scss';
@import '../base/colors.scss';

main {
	width: 100%;
}

.hero {
	width: 100%;
	height: 236px;
	flex: none;
	order: 1;
	flex-grow: 0;
	z-index: 1;

	&-background {
		position: absolute;
		width: 100%;
		height: 236px;
		overflow: hidden;
		background: $purple-10;
	}

	&-illustration {
		position: absolute;
		width: 1920px;
		height: 1120.09px;
		left: 230.16px;
		top: -66px;
	}

	&-ellipse {
		position: absolute;
		width: 389.89px;
		height: 358.76px;
		left: -70.81px;
		top: -73.51px;
		border-radius: 50%;
		background: $purple-120;
	}

	&-title {
		position: absolute;
		width: 259.71px;
		height: 65px;
		left: 60.3px;
		top: 50.07px;
		color: $white;
	}

	&-button {
		position: absolute;
		width: 174px;
		height: 48px;
		left: 51.16px;
		top: 132px;
		border-color: $white !important;
	}
}

.dashboard {
	width: 100%;
	box-sizing: border-box;
	flex: none;
	order: 2;
	flex-grow: 0;

	&-loading {
		grid-column: 1 / -1;
		margin-top: 32px;
	}

	&-message {
		grid-column: 1 / -1;
		background: $yellow;
		border-radius: 12px;
		margin-top: 24px;
	}

	&-h3 {
		grid-column: 1 / -1;
		color: $gray-46;
	}

	&-bookings {
		list-style-type: none;
		margin: 0;
		padding: 0;
		grid-column: 1 / -1;
		display: grid;
		grid-auto-columns: calc((100% - 11 * $column-gap) / 12);
		grid-auto-flow: column;
		column-gap: $column-gap;
		overflow-x: auto;

		&-item {
			grid-column: span 3;
			margin-bottom: 10px;
		}

		@media (max-width: $breakpoint-mq5) {
			&-item {
				grid-column: span 3;
			}
		}

		@media (max-width: $breakpoint-mq4) {
			&-item {
				grid-column: span 4;
			}
		}

		@media (max-width: $breakpoint-mq3) {
			&-item {
				grid-column: span 6;
			}
		}

		@media (max-width: $breakpoint-mq2) {
			&-item {
				grid-column: span 12;
			}
		}

		@media (max-width: $breakpoint-mq1) {
			&-item {
				grid-column: span 12;
			}
		}
	}

	&-empty {
		grid-column: 4 / 10;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		gap: 48px;
		height: 365px;
		flex: none;
		order: 0;
		flex-grow: 0;

		&-illustration {
			position: absolute;
			width: 202.37px;
			height: 237.56px;

			&-holder {
				width: 225px;
				height: 237px;
				flex: none;
				order: 0;
				flex-grow: 0;
			}
		}

		&-textbox {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0px;
			width: auto;
			flex: none;
			order: 1;
			flex-grow: 0;

			.second-start-booking-button {
				border-color: $purple-100;
				color: $purple-100;
			}
			.second-start-booking-button:hover {
				background-color: #312067;
			}
		}
	}
}
