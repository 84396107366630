.cancel-booking-modal {
	.modal-content {
		margin: 50px 0;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		.warning-icon {
			width: 100px;
			height: 100px;
		}
	}
}
